.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1{
  font-size: 50px;
  color: white;
}
.submit{
  position: absolute;
  right: 9px;
  top: 3px;
  /* background-color: #080321 !important; */
  width: 150px;
  padding: 10px;
}
.main_btn{
  padding: 13px 33px 11.7px;
  /* background: linear-gradient(to top,#00264c 50%,#ffb506 50%) no-repeat scroll right top/100% 210% #00264c!important; */
  background-color: #FEB504;
  /* border-radius: 15px; */
  border: none;
  color: white;
  font-family: hind,sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5385em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-radius: 3em;
}
.main_btn:hover{
  background-color: #00264c;
  color: white;
  -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.build_btn2{
  padding:18px 33px 16.7px;

  /* background: linear-gradient(to top,#00264c 50%,#ffb506 50%) no-repeat scroll right top/100% 210% #00264c!important; */
  /* background-color: #FEB504; */
  background-color: transparent;
  /* border-radius: 15px; */
  border: 2px solid black ;
  color: black;
  font-family: hind,sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5385em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-radius: 3em;
}
.build_btn{
  padding:18px 33px 16.7px;

  /* background: linear-gradient(to top,#00264c 50%,#ffb506 50%) no-repeat scroll right top/100% 210% #00264c!important; */
  /* background-color: #FEB504; */
  background-color: transparent;
  /* border-radius: 15px; */
  border: 2px solid white ;
  color: white;
  font-family: hind,sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5385em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-radius: 3em;
}
.build_btn:hover{
  color: #FEB504;
  border: 2px solid #FEB504;
}
.build_btn2:hover{
  color: #FEB504;
  border: 2px solid #FEB504;
}
.button {
  border: transparent !important;
     border-radius: 34.5px !important;
     padding: 13px 33px 11.7px;
     font-size: 13px !important;
     font-weight: 500 !important;
     box-shadow: -6px -6px 10px rgba(253,251,211,.2), 6px 6px 10px rgba(0,0,0,.8);
     /* margin-inline: .5rem; */
     color: #ffff;
     display: block;
     line-height: 1.5385em;
     background: linear-gradient(to right, #00264c  50%,#FEB504  50%);
     background-size: 200% 100%;
     background-position: right bottom;
     transition: all .5s ease-out;
     cursor: pointer;
   }
   
   .button:hover {
     /* background-position: left bottom; */
     background-position: left top;
     color: black;
   }
   
   .text {
    border: none;
    color: white;
    font-family: hind,sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5385em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .1em;
    border-radius: 3em;

     text-align: center;
     font-size: 16px;
     line-height: 30px; 
     color: white;
     transition: all .6s ease-out;
     display: block;
   }
   
   .text:hover {
     color: white;  
   }
   .para{
    color: #A3B1C7;
    font-size: 18px;
   }
   .bold{
     color: #8d949e;
     font-size: 13px;
    line-height: 1.8em;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: .1em; 
   }
.start p{
  text-align: start;
  padding: 2px;
}
.box{
  /* padding: 30px; */
  border-radius: 10px;
  border: none !important;
}
.box:hover{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
}
.box :hover p{
background-image: url(../src/assets/bg_hover.png);
/* background-position: center; */
background-size: cover;
height: 50px;
width: 50px;
background-repeat: no-repeat;

}
.box p{
  padding: 10px;
}
.icon{
  cursor: pointer;

}
.icon_clr{
  background-color: #00264c;
  padding: 19px;
  border-radius: 50px;
}
.icon_clr:hover{
  background-color: #FEB504;
}
.bg-img{
  background-image: url(../src/assets/WhatsApp\ Image\ 2023-09-04\ at\ 8.42.37\ PM.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 43.5em;
}
.footer{
  min-height: 43.5em !important;
    /* padding-top: 10.485vw; */
    background-position: center top!important;
    background-size: cover!important;
    background-color:white;
}
.header_bg{
  background-image: url(./assets/New_bg-01.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 45.5em !important;
  position: relative;
  overflow: hidden;
 }
 .header_bg:before {
  content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: 15.7vw;
    background-image: url(../src/assets/x3.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    display: block;
}
 .img_logo{
  width: 200px;
 }
 .border_price{
  border-left: 1px solid #A3B1C7;
  padding-left: 25px;
 }
 
.img_height{
  height: 500px;
  /* width: 700px; */
}

h3{
  font-size: 2.667em;
  color: #002140;
}
p,li{
  color: #8d949e;
  text-align: justify;
}
.evo_img{
  width:1200px;
  height: 800px;
  margin-top: -500px;
  position: absolute;
  z-index: -1;
  left: 0;
}
.bg_metaverse{
  background-image: url(../src/assets/bg-15-01-01-01-01-01-01.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 36.5em !important;
}
.bg_hackon{
  background-image: url(../src/assets/bg-16-01.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 35.5em !important;
}
.bg_token{
  background-image: url(../src/assets/tokenomic_bg-01\ \(1\).png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* min-height: 42.5em !important; */
  height: 1000px;
}
.hack{
  /* height: 400px; */
  width: 400px;
}
.mater{
  height: 550px;
  /* width: 800px; */
}
.overflow{
  overflow: hidden;
}
.mobile_img{
  position: absolute;
  overflow: hidden;
  bottom: 50px;
  top: 15%;
  /* right: 10px; */
  left: 31%;
  transform: translateY(200px); /* Increased initial position */
  transition: all 2s ease;
  /* th: 1000px; */
  /* right: 10px; */
  /* left: 90%; */
    /* height: 255px; */
}
.icon_lbank{
  width: 250px;
  height: 250px;
}
.lbank:hover{
  text-decoration: underline;
  cursor: pointer;
}
.box img{
  height: 120px;
  width: 120px;
}
.mobile_img{
  /* height: 700px; */
  /* overflow: hidden; */
  /* position: absolute;
  display: block;
  overflow:visible;
  z-index: 6;
    color: rgb(255, 255, 255);
    width: 1186px;
    height: 1319px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
    /* opacity: 0; */
    /* position: relative;
    visibility: hidden; 
    white-space: nowrap;
    display: block;
    -webkit-font-smoothing: antialiased!important;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
    z-index: 1;
    font-display: swap;
    position: absolute;
    display: block;
    pointer-events: auto;
    transform: translate(0px, 127px);
    left: 599px;
    top: 170px;
    z-index: 6;
    visibility: visible;
    perspective: 601px;  */
}
@media (max-width:1350px) {
.evo_img{
  width: 900px;
}
@media (max-width:1150px) {
  .evo_img{
    width: 800px;
  }
  .header_bg {
    background-position: right;
    /* min-height: 45.5em !important; */
   /* top: 25%; */
}
.mobile_img{
  top:36%
}
.build_btn,.build_btn2{
  padding: 13px;
  margin-left: 10px;
  margin-bottom: 20px;
 }
}

}
   @media (max-width:600px) {
    .mbl_wid{
      width: 100% !important;
    }
    .box{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 20px 0px;
      padding: 0;
    }
    .icon_clr{
      padding: 16px;
    }
     h1{
      font-size: 18px;
  
     }
     .header_bg {
      background-position: right;
      /* min-height: 45.5em !important; */
     /* top: 25%; */
  }
  
  .mobile_img{
    top:35%
  }
     .button{
      padding: 10px;
     }
     .build_btn,.build_btn2{
      padding: 13px;
      margin-left: 10px;
      margin-bottom: 20px;
     }

     .header_bg{
      /* max-height: 300px; */
      min-height: 310px !important;
     }
     .header_bg:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      height: 15.7vw;
      background-image: url(../src/assets/x3.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      display: block;
  }
  .img_height{
    height: 300px;
  }
  .mater{
    height: 350px;
  }
  .container{
    margin-top: 20px;
  }
  .box{
    margin-bottom: 20px;
  }
  .bg_token{
  height: 650px;
  }
   }
   @media (max-width:366px) {
    .build_btn, .build_btn2{
      padding: 13px !important;
      font-size: 12px;
    }
    .icon_clr{
      padding: 8px;
    }
   }






   .lucky-numbers-generator {
    font-family: 'Arial', sans-serif;
    background: #479a70;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px auto;
    max-width: 100vw; /* Adjusted for full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .input-area {
    margin-bottom: 20px;
  }
  
  .input-area input, .input-area button {
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .input-area button {
    background-color: #ecd383;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .input-area button:hover {
    background-color: #ddb957;
  }
  
  .numbers-display h2 {
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  .numbers-list {
    display: flex;
    flex-direction: column;
    text-align: start;
    /* display: inline-block; */
    padding: 10px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .number {
    font-weight: bold;
    color: #479a70;
    margin: 0 5px;
  }
  
  .number-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Larger min size for balls */
    gap: 30px; /* Increased gap for spacing */
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 100px); /* Adjusted for viewport height minus some margin */
    overflow: auto; /* Enable scrolling if content overflows */
  }
  
  .number-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px; /* Increased width */
    height: 40px; /* Increased height */
    border-radius: 10px;
    background-color: #ecd383;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    overflow: hidden; /* Hide overflow text */
  }
  
  .name {
    padding: 10px;
    font-size: smaller; /* Adjust if needed */
    text-align: center;
  }
  
  .number-circle.selected {
    background-color: #f77;
    color: #ffffff;
  }
  
  /* Animation and keyframes for selected balls */
  .number-circle.selected {
    animation: popIn 0.5s ease-out forwards;
  }
  
  @keyframes popIn {
    0% { transform: scale(0); }
    85% { transform: scale(1.25); }
    100% { transform: scale(1); background-color: #f77; }
  }
  
  /* Suspense animation for balls */
  .number-circle.suspense {
    animation: suspenseAnimation 1s ease-in-out infinite;
  }
  
  @keyframes suspenseAnimation {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  .number-circle.disabled {
    background-color: #ccc; /* Grayed out background */
    color: #666; /* Darker text color */
    pointer-events: none; /* No interaction */
    opacity: 0.6; /* Slightly transparent */
  }
  
  /* ... existing styles ... */

.number-circle.animating {
  background-color: #f77; /* Red background */
  color: #ffffff; /* White text */
  animation: none; /* No animation */
}

/* Adjust the keyframes to include the disabled state after popping in */
@keyframes popIn {
  0% { transform: scale(0); }
  50% { transform: scale(1.25); }
  75% { background-color: #f77; }
  100% { transform: scale(1); background-color: #ccc; } /* Transition to disabled */
}

/* Update the .disabled class to reflect the end state of the animation */
.number-circle.disabled {
  background-color: #ccc; /* Grayed out background */
  color: #666; /* Darker text color */
  pointer-events: none; /* No interaction */
  opacity: 0.6; /* Slightly transparent */
}

/* ... existing styles ... */

.number-circle {
  /* ... other styles ... */
  transition: transform 0.3s, background-color 0.3s, opacity 0.3s;
}

.number-circle.animating {
  background-color: #f77; /* Red background for animating */
  color: #ffffff; /* White text for contrast */
  /* No need for animation here */
}

.number-circle.disabled {
  background-color: #ccc; /* Grayed out background */
  color: #666; /* Darker text color */
  pointer-events: none; /* No interaction */
  opacity: 0.6; /* Slightly transparent */
}

/* Remove the previous popIn keyframes */

/* Add new animation for animating state that ends with disabled look */
@keyframes animatingToDisabled {
  0%, 50% {
    transform: scale(1);
    background-color: #f77;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    background-color: #ccc;
    opacity: 0.6;
  }
}

.number-circle.animating {
  animation: animatingToDisabled 2.5s forwards; /* Time should match the speaking delay */
}
