.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    /* #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100% */
      #d0f11a 20%,
    #fb0959 67%,
    #7b7398 0%,
    white 29%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  /* display: inline-block; */
  font-size: 50px;
}
.animate-charcter2 {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #0a3a19 20%,
    #fb0959 67%,
    #7b7398 0%,
    #cdaeed 29%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  /* display: inline-block; */
  /* font-size: 30px; */
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* 
  .ticket {
    display: flex;
    font-family: Roboto;
    margin: 16px;
    border: 1px solid #e0e0e0;
    position: relative;
    margin-top: 35px;
    margin-bottom: 50px;
  }
  .ticket:before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    transform: rotate(-45deg);
    left: -18px;
    top: 50%;
    margin-top: -16px;
    border-radius: 50%;
  }
  .ticket:after {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    transform: rotate(135deg);
    right: -18px;
    top: 50%;
    margin-top: -16px;
    border-radius: 50%;
  }
  .ticket--start {
    position: relative;
    border-right: 1px dashed #e0e0e0;
  }
  .ticket--start:before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    position: absolute;
    transform: rotate(-45deg);
    left: -18px;
    top: -2px;
    margin-top: -16px;
    border-radius: 50%;
  }
  .ticket--start:after {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    transform: rotate(-45deg);
    left: -18px;
    top: 100%;
    margin-top: -16px;
    border-radius: 50%;
  }
  .ticket--start > img {
    display: block;
    padding: 24px;
    height: 270px;
  }
  .ticket--center {
    padding: 24px;
    flex: 1;
  }
  .ticket--center--row {
    display: flex;
  }
  .ticket--center--row:not(:last-child) {
    padding-bottom: 48px;
  }
  .ticket--center--row:first-child span {
    color: #4872b0;
    text-transform: uppercase;
    line-height: 24px;
    font-size: 13px;
    font-weight: 500;
  }
  .ticket--center--row:first-child strong {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .ticket--center--col {
    display: flex;
    flex: 1;
    width: 50%;
    box-sizing: border-box;
    flex-direction: column;
  }
  .ticket--center--col:not(:last-child) {
    padding-right: 16px;
  }
  .ticket--end {
    padding: 24px;
    background-color: #4872b0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .ticket--end:before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    transform: rotate(-45deg);
    right: -18px;
    top: -2px;
    margin-top: -16px;
    border-radius: 50%;
  }
  .ticket--end:after {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    transform: rotate(-45deg);
    right: -18px;
    top: 100%;
    margin-top: -16px;
    border-radius: 50%;
  }
  .ticket--end > div:first-child {
    flex: 1;
  }
  .ticket--end > div:first-child > img {
    width: 128px;
    padding: 4px;
    background-color: #fff;
  }
  .ticket--end > div:last-child > img {
    display: block;
    margin: 0 auto;
    filter: brightness(0) invert(1);
    opacity: 0.64;
  }
  .ticket--info--title {
    text-transform: uppercase;
    color: #757575;
    font-size: 13px;
    line-height: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ticket--info--subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ticket--info--content {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    font-weight: bold;
  } */

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-group {
  display: flex;
  flex-wrap: nowrap;
  width: 50%; /* Adjust width to match other inputs */
  margin: auto;
  margin-top: 15px; /* Adjust margin to space out from previous elements */
}

.form-control {
  background-color: transparent;
  flex-grow: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid rgb(231, 227, 227);
  padding: 10px;
  color: white !important;
}
.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent;
  /* border: none !important; */
}
.btn-success:focus {
  box-shadow: none !important;
}
.btn-success {
  background-color: transparent;
  border: 1px solid rgb(231, 227, 227);
}
.btn-success:hover {
  background-color: transparent;
  opacity: 0.65;
  border: 1px solid rgb(231, 227, 227);
}
.input-group-btn {
  flex-shrink: 0; /* Prevent button from shrinking */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bg_event {
  background-image: url(../assets/ticket\ backgrounddd.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 115vh;
}
.bg_event2 {
  background-image: url(../assets/ticket\ backgrounddd.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
input {
  background-color: transparent;
  border: 1px solid rgb(231, 227, 227);
  border-radius: 3px;
  color: white;
}
@media screen and (max-width: 600px) {
  .input-group {
    width: 100%;
  }
  .mobile_with {
    width: 100% !important;
  }
  .ticket {
    display: block;
    margin-top: 25px;
  }
  .bg_event {
    height: auto;
  }
}

.ticket {
  background: linear-gradient(145deg, #6e48aa, #9d50bb);
  color: #fff;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.ticket:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.qr-code {
  width: 200px;
  height: 200px;
  border-radius: 12px;
}

.event-info {
  margin-left: 1rem;
}

@media (max-width: 767px) {
  .ticket {
    margin-top: 1rem;
  }
  .qr-code {
    width: 80px;
    height: 80px;
  }
  .event-info {
    margin-left: 0;
    margin-top: 1rem;
  }
}
