body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  /* font-family: 'Helvetica Neue', sans-serif; */
  font-family: "Rubik", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4 {
  /* font-family: 'Helvetica Neue', sans-serif !important; */
  font-weight: 700;
}
.container {
  margin-top: 50px;
}
